import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from 'lodash'

export default function AcceptedValueSetAutocomplete(props) {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(props.target_value_set_name)

  useEffect(() => {
    const hiddenInput = document.querySelector('input[name="rule[target_value_set_name]"]')
    if (hiddenInput) {
      hiddenInput.value = value || ''
    }
  }, [value])

  const handleChange = debounce((event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value },
      })
        .done((response) => {
          setOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setOptions([])
    }
  }, 300)

  const getOptionLabel = (option) => option

  return (
    <>
      <Autocomplete
        freeSolo
        id="tags-standard"
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            variant="standard"
            label="Accepted Value Set"
            sx={{ mt: -4 }}
          />
        )}
        value={value}
        onChange={(_event, newValue) => setValue(newValue || null)}
      />
      <input type="hidden" name="rule[target_value_set_name]" value={value} />
    </>
  )
}
