import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid } from '@mui/material'

const propTypes = {
  action: PropTypes.string,
  updateAction: PropTypes.func.isRequired,
  ordersEnabledUi: PropTypes.bool.isRequired,
}

const defaultProps = {
  action: '',
}

const Action = ({ action, updateAction, ordersEnabledUi }) => {
  const [editing, setEditing] = useState(action === '' || action === null)
  const [editableAction, setEditableAction] = useState(action)
  const textarea = useRef(null)

  const toggleAndFocus = () => {
    if (ordersEnabledUi) {
      setEditing(true)
      setTimeout(() => textarea.current.focus(), 300)
    } else {
      setEditing(false)
    }
  }

  const handleClick = (event) => {
    if (!editing) {
      event.preventDefault()
      toggleAndFocus()
    }
  }

  const saveAction = (act) => {
    updateAction(act)
  }

  const autosave = useCallback(_.debounce((act) => { saveAction(act) }, 1000), [])

  const handleBlur = () => {
    setEditing(false)
    saveAction(editableAction)
  }

  const handleChange = (event) => {
    setEditableAction(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !editing) {
      // User hit enter key
      event.preventDefault()
      toggleAndFocus()
    }
  }

  const content = editing ? (
    <textarea
      className="col s9 textarea"
      onBlur={handleBlur}
      value={editableAction}
      onChange={handleChange}
      ref={textarea}
      maxLength="200"
    />
  ) : (
    <div role="button" onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0}>
      {editableAction}
    </div>
  )

  return (
    <Grid
      container
      direction="row"
    >
      <Grid
        item
        xs={3}
      >
        <b>Action</b>
      </Grid>
      <Grid
        item
        xs={9}
      >
        { content }
      </Grid>
    </Grid>
  )
}

Action.propTypes = propTypes
Action.defaultProps = defaultProps
export default Action
