import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Autocomplete, TextField, Chip } from '@mui/material'
import PropTypes from 'prop-types'

export default function OrderCoverage({ rule, valueSetRule }) {
  const editRule = rule ? true : false
  const ruleLink = 'https://app.avhana.com/diagnosis_codes?insurance={insurance_value_set_name}&order={order_value_set}&card_uuid={card_uuid}'
  const uuid = editRule ? rule.uuid : uuidv4()
  const ruleCategory = "order_coverage"
  const ruleType = "advisory"
  const active = "false"
  const medicationSubstitution = "false"
  const [name, setName] = useState(editRule ? rule.name : '')
  const [action, setAction] = useState(editRule ? rule.action : '')
  const [description, setDescription] = useState(editRule ? rule.description : '')
  const [source, setSource] = useState(editRule ? rule.source : '')
  const [link, setLink] = useState(editRule ? rule.link : ruleLink)
  const [orderValueSetOptions, setOrderValueSetOptions] = useState([])
  const [orderValueSetName, setOrderValueSetName] = useState(editRule ? valueSetRule.value_set_name : null)
  const [diagnosisValueSetOptions, setDiagnosisValueSetOptions] = useState([])
  const [diagnosisValueSetName, setDiagnosisValueSetName] = useState(editRule ? valueSetRule.diagnosis_value_set_name : null)
  const [clinicalProviderValueSetOptions, setClinicalProviderValueSetOptions] = useState([])
  const [clinicalProviderValueSetName, setClinicalProviderValueSetName] = useState(editRule ? valueSetRule.clinical_provider_value_set_name : null)
  const [insuranceValueSetOptions, setInsuranceValueSetOptions] = useState([])
  const [insuranceValueSetNames, setInsuranceValueSetNames] = useState([])

  const handleOrderValueSetChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value, code_system: "ATHENA_ORDER_TYPE" },
      })
        .done((response) => {
          setOrderValueSetOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setOrderValueSetOptions([])
    }
  }

  const handleDiagnosisValueSetChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value, code_system: "ICD10CM" },
      })
        .done((response) => {
          setDiagnosisValueSetOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setDiagnosisValueSetOptions([])
    }
  }

  const handleInsuranceValueSetChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value, code_system: "INSURANCE" },
      })
        .done((response) => {
          setInsuranceValueSetOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setInsuranceValueSetOptions([])
    }
  }

  const handleClinicalProviderValueSetChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value, code_system: "ATHENA_CLINICAL_PROVIDER" },
      })
        .done((response) => {
          setClinicalProviderValueSetOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setClinicalProviderValueSetOptions([])
    }
  }

  const getOptionLabel = (option) => option

  const createRule = (formData) => {
    $.ajax({
      method: 'POST',
      url: '/root/rules',
      contentType: false,
      processData: false,
      data: formData,
    })
    .done((response) => {
      if (response.rule_id) {
      window.location.href = `/root/rules/${response.rule_id}`
      } else {
        console.error('rule_id not found in response')
      }
    })
    .fail((error) => {
      console.error(`Error creating your order coverage rule. ${error}`)
    })
  }

  const updateRule = (formData) => {
    $.ajax({
      method: 'PUT',
      url: `/root/rules/${rule.id}`,
      contentType: false,
      processData: false,
      data: formData,
    })
    .done(() => {
      window.location.href = `/root/rules`
    })
    .fail((error) => {
      console.error(`Error updating your order coverage rule ${rule.id}. ${error}`)
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("rule[name]", name)
    formData.append("rule[rule_action]", action)
    formData.append("rule[description]", description)
    formData.append("rule[source]", source)
    formData.append("rule[link]", link)
    formData.append("rule[rule_type]", ruleType)
    formData.append("rule[active]", active)
    formData.append("rule[medication_substitution]", medicationSubstitution)
    formData.append("rule[uuid]", uuid)
    formData.append("rule[rule_category]", ruleCategory)
    formData.append("rule[order_value_set_name]", orderValueSetName)
    formData.append("rule[diagnosis_value_set_name]", diagnosisValueSetName)
    formData.append("rule[clinical_provider_value_set_name]", clinicalProviderValueSetName)
    formData.append("rule[insurance_value_set_names]", insuranceValueSetNames)

    if (valueSetRule) {
      updateRule(formData)
    } else {
      createRule(formData)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Rule Name"
        value={name}
        onChange={event => setName(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Action"
        value={action}
        onChange={event => setAction(event.target.value)}
        helperText="200 character limit"
        fullWidth
        InputProps={{maxLength: 200, disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Description"
        value={description}
        onChange={event => setDescription(event.target.value)}
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Rule Source (ex. 'Medicare, via AmalgamRx')"
        value={source}
        onChange={event => setSource(event.target.value)}
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Rule Link"
        value={link}
        onChange={event => setLink(event.target.value)}
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Rule Uuid"
        defaultValue={uuid}
        disabled
        fullWidth
        variant="standard"
        InputProps={{readOnly: true, disableUnderline: true}}
      />
      <Autocomplete
        id="order-value-set-name"
        options={orderValueSetOptions}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} required onChange={handleOrderValueSetChange} label="Order Value Set Name" InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        value={orderValueSetName}
        onChange={(_event, value, reason) => { if (reason === 'selectOption') { setOrderValueSetName(value) } }}
      />
      <Autocomplete
        id="diagnosis-value-set-name"
        options={diagnosisValueSetOptions}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} required onChange={handleDiagnosisValueSetChange} label="Diagnosis Value Set Name" InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        value={diagnosisValueSetName}
        onChange={(_event, value, reason) => { if (reason === 'selectOption') { setDiagnosisValueSetName(value) } }}
      />
      <Autocomplete
        freeSolo 
        multiple
        filterSelectedOptions
        id="insurance-value-set-name"
        options={insuranceValueSetOptions}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} required onChange={handleInsuranceValueSetChange} label="Insurance Value Set Names" InputProps={{...params.InputProps, disableUnderline: true, required: insuranceValueSetNames.length === 0}} variant="standard"/>}
        value={insuranceValueSetNames} 
        onChange={(_event, value, reason) => { if (reason === 'selectOption') { 
          const v = value[value.length - 1]
          setInsuranceValueSetNames([...insuranceValueSetNames, v]) 
        } }}
        renderTags={(_, getTagProps) => {
          return insuranceValueSetNames.map((name, index) => (
            <Chip
              variant="outlined"
              label={name}
              {...getTagProps({ index })}
              onDelete={() => {
                setInsuranceValueSetNames(insuranceValueSetNames.filter((v) => v !== name))
              }}
            />
          ))
        }}
      />
      <Autocomplete
        id="clinical-provider-value-set-name"
        options={clinicalProviderValueSetOptions}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} onChange={handleClinicalProviderValueSetChange} label="Clinical Provider Value Set Name" InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        value={clinicalProviderValueSetName}
        onChange={(_event, value, reason) => { if (reason === 'selectOption') { setClinicalProviderValueSetName(value) } }}
      />
      <button type="submit">Submit</button>
    </form>
  )
}

OrderCoverage.propTypes = {
  rule: PropTypes.object,
  valueSetRule: PropTypes.object,
}