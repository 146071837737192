import React, { useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const TagSelect = ({ parentHandler, ruleTags: initialRuleTags, tags }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [ruleTags, setRuleTags] = useState(initialRuleTags)
  const [allTags] = useState(tags)

  const handleChange = (option) => {
    setSelectedOption(option)
    setRuleTags([...ruleTags, option])
    parentHandler({ id: option.value, name: option.label })
  }

  const tagOptions = () => allTags.map((t) => ({
    value: t.id,
    label: t.name
  }))

  return (
    <div style={{ padding: '0em 0em 1em 1em' }}>
      <Select
        className="tagSelect"
        classNamePrefix="tagSelect"  // Prefix for nested classes
        placeholder="Add a tag"
        value={selectedOption}
        onChange={handleChange}
        options={tagOptions()}
      />
    </div>
  )
}

const TAG = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

TagSelect.propTypes = {
  parentHandler: PropTypes.func.isRequired,
  ruleTags: PropTypes.arrayOf(TAG).isRequired,
  tags: PropTypes.arrayOf(TAG).isRequired,
}

export default TagSelect